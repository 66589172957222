<template>
  <div>
    <TitleToolbar
      title="Neuigkeiten"
    />
    <!-- Filters -->
    <v-container fluid>
      <v-row dense>
        <v-col class="text-right">
          <v-btn
            text
            :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
            @click="showFilters = true"
          >
            FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
          </v-btn>
          <v-btn
            v-if="computedFiltersActive > 0"
            icon
            color="secondary"
            @click="resetFilters()"
          >
            <v-icon small>fas fa-trash</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <div
      ref="newsEditorContainer"
    >
      <NewsEditor
        class="mb-6"
        v-show="
          user &&
          ac &&
          (
            ac.can(user.role).createAny('news').granted ||
            ac.can(user.role).createOwn('news').granted
          )
        "
        ref="newsEditor"
        :news="getNews($route.params.id)"
        :showEditor="showEditor"
        :class="($route.params.id && $route.params.id !== 'neu') ? ($vuetify.breakpoint.mdAndUp ? 'balance full': 'balance') : ''"
      />
    </div>
    <v-timeline
      ref="timeline"
      v-if="news && news.length > 0"
      dense
      :class="$vuetify.breakpoint.mdAndUp ? 'full' : ''"
      :align-top="!$vuetify.breakpoint.mdAndUp"
    >
      <v-timeline-item
        v-for="(item, i) in news"
        :key="i"
        :ref="item._id"
        :color="item.isAccepted === false ? 'error' : 'secondary'"
        fill-dot
        small
        class="mb-6"
      >
        <template v-slot:icon>
          <div v-if="$vuetify.breakpoint.mdAndUp" class="arrow" :class="item.isAccepted === false ? 'notAccepted' : ''">
            <v-row>
              <v-col cols="12" class="timeline-item-date text-uppercase font-weight-bold pa-0 ma-0 pt-5 white--text">
                {{$moment(item.updatedAt).format('DD. MMM YYYY')}}
              </v-col>
              <v-col cols="12" class="timeline-item-time pa-0 ma-0 white--text">
                {{$moment(item.updatedAt).format('HH:mm')}} Uhr
              </v-col>
            </v-row>
          </div>
          <div class="circle"></div>
        </template>
        <div>
          <div v-if="!$vuetify.breakpoint.mdAndUp" class="arrow small" :class="item.isAccepted === false ? 'notAccepted' : ''">
            <v-row class="pt-3">
              <v-col cols="12" class="text-uppercase font-weight-bold pa-0 ma-0 white--text">
                {{$moment(item.updatedAt).format('DD. MMM YYYY')}}
              </v-col>
              <v-col cols="12" class="pa-0 ma-0 white--text">
                {{$moment(item.updatedAt).format('HH:mm')}} Uhr
              </v-col>
            </v-row>
          </div>
          <v-card
            color="transparent"
            flat
            max-width="800"
            style="margin-left: -10px; margin-top: -10px"
          >
            <v-row
              class="clickable"
              @click="showOrganisation(getOrganisation(item.organisation))"
            >
              <v-col align-self="center" cols="12" sm="6">
                <div class="primary--text timeline-item-description">{{getOrganisation(item.organisation).name}}</div>
              </v-col>
              <v-col align-self="center" cols="12" sm="6" class="py-0 text-right">
                <v-img
                  contain
                  :position="$vuetify.breakpoint.smAndUp ? 'right' : 'left'"
                  max-height="60px"
                  :src="s3 + getOrganisation(item.organisation).pic?.name"
                  :class="$vuetify.breakpoint.smAndUp ? '' : 'my-1'"
                >
                </v-img>
              </v-col>
            </v-row>
            <v-row v-if="item.pics.length > 0">
              <v-col>
                <v-carousel
                  cycle
                  hide-delimiter-background
                  :hide-delimiters="!(item.pics.length > 1)"
                  :show-arrows="item.pics.length > 1"
                  :show-arrows-on-hover="item.pics.length > 1"
                  class="flat-carousel"
                  :class="item.pics.length > 1 ? 'flat-carousel-show-delimiters' : ''"
                  height="auto"
                >
                  <v-carousel-item
                    v-for="(pic, i) in item.pics"
                    :key="i"
                  >
                    <v-img
                      :aspect-ratio="16/9"
                      :src="s3 + pic.name"
                    >
                    </v-img>
                  </v-carousel-item>
                </v-carousel>
              </v-col>
            </v-row>
            <v-card-title class="primary--text timeline-item-title mx-0 px-0 py-1 my-3">
              {{item.title}}
              <v-btn
                v-if="
                  !$route.params.id &&
                  user &&
                  ac &&
                  (
                    ac.can(user.role).updateAny('news').granted ||
                    (
                      ac.can(user.role).updateOwn('news').granted &&
                      item.organisation === user.organisation
                    )
                  )
                "
                class="ml-3"
                color="secondary"
                icon
                @click="$router.push($route.path  + '/editor/' + item._id)"
              >
                <v-icon small>fas fa-edit</v-icon>
              </v-btn>
            </v-card-title>
            <v-col class="error--text card-title pb-3 px-0 pt-0" v-if="item.isAccepted === false">NOCH NICHT FREIGEGEBEN</v-col>
            <v-card-text class="primary--text services-description ma-0 pa-0 pretext">
              <v-row>
                <v-col v-html="$sanitize(item.info)"></v-col>
              </v-row>
              <v-row class="py-1" v-for="(link, i) in item.links" :key="i">
                <v-col cols="12">
                  <template v-if="link.isVideo">
                    <div
                      class="responsive-video"
                    >
                      <iframe width="100%" :src="link.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                    </div>
                  </template>
                  <template v-else>
                    <v-icon small color="primary" class="mb-1 mr-3">fas fa-external-link-alt</v-icon>
                    <a target="_blank" :href="link.url">{{link.text || link.url}}</a>
                  </template>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
      </v-timeline-item>
    </v-timeline>
    <v-row v-if="computedTotal === 0">
      <v-col  class="info--text text-center pa-0" cols="12">
        Keine Einträge vorhanden
      </v-col>
    </v-row>
    <v-row v-if="computedTotal > 0" :style="$vuetify.breakpoint.mdAndUp ? 'margin-left: 250px;': ''" :class="$vuetify.breakpoint.mdAndUp ? 'pa-0 mr-10 mt-3' : 'pa-0 mx-10 mt-3'">
      <v-col algin-self="center" class="pa-0" cols="12" sm="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <v-btn
            v-if="(skip + limit) < computedTotal"
            tile
            text
            depressed
            :color="hover ? 'secondary' : 'info'"
            class="white--text"
            @click="previousPage()"
          >
            <v-icon small class="mr-3">fas fa-chevron-left</v-icon>
            Ältere anzeigen
          </v-btn>
        </v-hover>
      </v-col>
      <v-col align-self="center" class="info--text text-center pa-0" cols="12" sm="4">
        <template v-if="skip+limit <= computedTotal">
        {{skip+1}}-{{skip+limit}} von {{computedTotal}}
        </template>
        <template v-else>
          {{skip+1}}-{{computedTotal}} von {{computedTotal}}
        </template>
      </v-col>
      <v-col align-self="center" class="text-right pa-0" cols="12" sm="4">
        <v-hover
          v-slot:default="{ hover }"
        >
          <v-btn
            v-if="skip > 0"
            tile
            text
            depressed
            :color="hover ? 'secondary' : 'info'"
            class="white--text"
            @click="nextPage()"
          >
            Neuere anzeigen
            <v-icon small class="ml-3">fas fa-chevron-right</v-icon>
          </v-btn>
        </v-hover>
      </v-col>
    </v-row>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col>
              <v-dialog
                ref="filterDatesDialog"
                v-model="showFilterDatesDialog"
                :return-value.sync="filterDates"
                persistent
                width="320px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    color="secondary"
                    :value="computedFilterDates"
                    @click:clear="filterDates = []"
                    label="Zeitraum"
                    append-icon="fas fa-calendar-day"
                    readonly
                    id="filterDatesInput"
                    v-on="on"
                    clearable
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-if="showFilterDatesDialog"
                  v-model="filterDates"
                  full-width
                  format="24hr"
                  color="secondary"
                  range
                  first-day-of-week="1"
                >
                  <v-spacer></v-spacer>
                  <v-hover
                    v-slot:default="{ hover }"
                  >
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="showFilterDatesDialog = false"
                    >
                      Abbrechen
                    </v-btn>
                  </v-hover>
                  <v-hover
                    v-slot:default="{ hover }"
                  >
                    <v-btn
                      tile
                      depressed
                      :color="hover ? 'secondary' : 'info'"
                      class="white--text"
                      @click="$refs.filterDatesDialog.save(filterDates)"
                    >
                      OK
                    </v-btn>
                  </v-hover>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Organisation(en)"
                multiple
                v-model="filterOrganisations"
                clearable
                color="secondary"
                item-color="secondary"
                :items="computedOrganisations"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <template v-if="user">
          <v-row>
            <v-col cols="12">
              <div class="body-1">Neuigkeiten alle / eigene</div>
              <v-checkbox
                color="secondary"
                label="Nur eigene anzeigen"
                v-model="filterOwn"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          </template>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import { makeFindMixin } from 'feathers-vuex'
import NewsEditor from '@/components/NewsEditor'
import TitleToolbar from '@/components/TitleToolbar'

export default {
  name: 'Timeline',
  components: {
    NewsEditor,
    TitleToolbar
  },
  mixins: [makeFindMixin({ service: 'news', watch: true })],
  data () {
    return {
      limit: 5,
      skip: 0,
      showEditor: false,
      showFilters: false,
      showFilterDatesDialog: false,
      filterDates: [],
      filterOwn: false,
      filterOrganisations: []
    }
  },
  mounted () {
    if (this.$route.query.s) {
      this.skip = parseInt(this.$route.query.s)
    }
    this.adaptParams()
    if (this.$route.params.own) {
      this.filterOwn = true
      delete this.$route.params.own
    }
  },
  methods: {
    ...mapActions('news', {
      requestNews: 'get'
    }),
    showOrganisation (organisation) {
      if (organisation.type === 'member' || organisation.type === 'institution') {
        this.$router.push('/mitglieder/' + organisation._id)
      } else if (organisation.link) {
        window.open(organisation.link, '_newtab')
      }
    },
    resetFilters () {
      this.filterDates = []
      this.filterOrganisations = []
      this.filterOwn = false
    },
    previousPage () {
      this.skip = this.skip + this.limit
    },
    nextPage () {
      this.skip = this.skip - this.limit
    },
    openEditor (news) {
      if (news) {
        this.$refs[news._id][0].$el.parentNode.insertBefore(this.$refs.newsEditor.$el, this.$refs[news._id][0].$el.nextSibling)
      }
      setTimeout(() => {
        this.$vuetify.goTo(this.$refs.newsEditor.$el, { offset: 0 })
        this.showEditor = true
      }, 100)
    },
    closeEditor () {
      this.showEditor = false
      this.$refs.newsEditorContainer.parentNode.insertBefore(this.$refs.newsEditor.$el, this.$refs.newsEditorContainer.nextSibling)
    },
    async adaptParams () {
      if (this.$route.params.id) {
        if (this.$route.params.id !== 'neu') {
          let tmpEvent = this.getNews(this.$route.params.id)
          if (!tmpEvent) {
            tmpEvent = await this.requestNews(this.$route.params.id)
          }
          this.openEditor(tmpEvent)
        } else {
          this.openEditor()
        }
      } else {
        this.closeEditor()
      }
    }
  },
  computed: {
    newsParams () {
      const query = { qid: 'news', query: { $or: [{ internal: false }, { internal: { $ne: true } }], $limit: this.limit, $skip: this.skip, $sort: { updatedAt: -1 } } }
      if (this.filterOrganisations.length > 0) {
        query.query.organisation = { $in: this.filterOrganisations }
      }
      if (this.filterOwn) {
        query.query.organisation = this.user.organisation
      }
      if (this.filterDates.length === 2) {
        query.query.updatedAt = { $gt: this.filterDates[0], $lt: this.filterDates[1] }
      }
      return query
    },
    ...mapGetters([
      'ac',
      's3'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('news', {
      getNews: 'get'
    }),
    ...mapGetters('organisations', {
      getOrganisation: 'get',
      organisations: 'list'
    }),
    computedFilterDates () {
      const tmpString = this.filterDates.map(obj => this.$moment(obj).format('DD.MM.YYYY'))
      return tmpString.join(' bis ')
    },
    computedOrganisations () {
      return this.organisations.filter(obj => obj.isActive).map(obj => { return { value: obj._id, text: obj.name } })
    },
    computedTotal () {
      try {
        return this.newsPaginationData.news.mostRecent.total
      } catch (e) {
        return 0
      }
    },
    computedFiltersActive () {
      let count = 0
      if (this.filterOrganisations.length !== 0) { ++count }
      if (this.filterDates.length !== 0) { ++count }
      if (this.filterOwn) { ++count }
      return count
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    skip () {
      if (parseInt(this.$route.query.s) !== this.skip) {
        this.$router.replace({ query: { s: this.skip } })
      }
    },
    filterDates () {
      if (this.filterDates.length === 2) {
        if (this.$moment(this.filterDates[0]).isAfter(this.$moment(this.filterDates[1]))) {
          const tmpDate = this.filterDates[0]
          this.filterDates[0] = this.filterDates[1]
          this.filterDates[1] = tmpDate
        }
      }
    },
    showEditor () {
      if (!this.showEditor) {
        this.findNews()
      }
    },
    user () {
      this.findNews()
    }
  }
}
</script>

<style scoped>
  .arrow {
    width: 150px !important;
    height: 52px;
    background: #31aec0;
    position: absolute;
    padding-top: 2px;
    padding-left: 25px;
    left: -140px;
  }
  .arrow.notAccepted {
    background: #ee6d64;
  }
  .arrow.small {
    position: relative;
    left: -10px;
    top: -14px
  }
  .arrow:after {
      position: absolute;
      top: 0px;
      right: -26px;
      content: "";
      width: 0;
      height: 0;
      border-top: 26px solid transparent;
      border-bottom: 26px solid transparent;
      border-left: 26px solid #31aec0;
  }
  .arrow.notAccepted:after {
    border-left: 26px solid #ee6d64;
  }
  .arrow.small:after {
    right: 140px;
    border-left: none;
    border-right: 26px solid #31aec0;
  }
  .arrow.small.notAccepted:after {
    border-right: 26px solid #ee6d64;
  }
  .circle {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    border-radius: 100%;
    left: 40px;
  }

  .v-timeline {
    left: -50px;
    width: calc(100% + 15px);
  }

  .v-timeline.full {
    left: 160px;
    max-width: calc(100% - 185px);
  }

  .balance {
    margin-left: 50px !important;
    width: calc(100% - 10px) !important;
  }

  .balance.full {
    margin-left: -160px !important;
    width: calc(100% + 200px) !important;
  }

  .responsive-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .responsive-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
    overflow: hidden;
  }

</style>
