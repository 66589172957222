<template>
  <div>
    <template
      v-if="event && organisation"
    >
      <TitleToolbar
        :title="title"
      />
      <v-container :fluid="!$vuetify.breakpoint.mdAndUp">
        <v-card
          tile
          outlined
          class="elevation-3"
        >
          <v-carousel
            height="auto"
            v-if="event.pics.length > 0"
            cycle
            hide-delimiter-background
            :hide-delimiters="!computedShowCarouselDelimiters"
            :show-arrows="computedShowCarouselDelimiters"
            :show-arrows-on-hover="computedShowCarouselDelimiters"
            class="flat-carousel"
          >
            <v-carousel-item
              v-for="(pic, i) in event.pics"
              :key="i"
            >
              <v-img
                :aspect-ratio="16/9"
                max-height="600"
                :src="s3 + pic.name"
              >
              </v-img>
            </v-carousel-item>
          </v-carousel>
          <v-card-text class="primary--text">
            <!-- Title row with dates -->
            <v-row class="ma-0 mt-4 px-0 mx-n3">
              <v-col>
                <v-row
                  dense
                  no-gutters
                  class="mb-4"
                  v-if="event.dates.length === 1"
                >
                  <v-col
                    cols="12"
                  >
                    <span class="event-detail-date">{{$moment(event.dates[0].start).format('dddd')}},&nbsp;</span>
                    <span class="event-detail-date-bold">{{$moment(event.dates[0].start).format('DD. MMMM YYYY')}}</span>
                    <template v-if="!$moment(event.dates[0].start).isSame($moment(event.dates[0].end), 'day')">
                      &nbsp;bis&nbsp;
                      <span class="event-detail-date">{{$moment(event.dates[0].end).format('dddd')}},&nbsp;</span>
                      <span class="event-detail-date-bold">{{$moment(event.dates[0].end).format('DD. MMMM YYYY')}}</span>
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="services-title pb-4">
                  <v-col>
                    {{event.title}}
                    <v-btn
                      class="ml-3 mb-1"
                      color="secondary"
                      icon
                      @click="$router.push($route.path + '/editor')"
                      v-if="
                        user &&
                        ac &&
                        (
                          ac.can(user.role).updateAny('event').granted ||
                          (
                            ac.can(user.role).updateOwn('event').granted &&
                            event.organisation === user.organisation
                          )
                        )
                      "
                    >
                      <v-icon>
                        fas fa-edit
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="event.subTitle" no-gutters class="services-secondary-title pb-4">
                  <v-col>
                    {{event.subTitle}}
                  </v-col>
                </v-row>
                <v-row
                  dense
                  no-gutters
                  class="mb-6"
                  v-if="event.dates.length > 1"
                >
                  <v-col
                    cols="12"
                    class="event-detail-date-bold mb-3"
                  >
                    TERMINE:
                  </v-col>
                  <v-col
                    cols="12"
                    v-for="(date, i) in displayedDates(event.dates)"
                    :key="i"
                  >
                    <span class="event-detail-date">{{$moment(date.start).format('dddd')}},&nbsp;</span>
                    <span class="event-detail-date-bold">{{$moment(date.start).format('DD. MMMM YYYY')}}</span>
                    <template v-if="!sameDay(date)">
                      <span class="event-detail-date-bold">&nbsp;-&nbsp;</span>
                      <span class="event-detail-date">{{$moment(date.end).format('dddd')}},&nbsp;</span>
                      <span class="event-detail-date-bold">{{$moment(date.end).format('DD. MMMM YYYY')}}</span>
                    </template>
                  </v-col>
                  <v-col cols="12" class="mt-4">
                    <v-btn
                      v-if="showCount < event.dates.length"
                      outlined
                      tile
                      color="secondary"
                      @click="showCount += 5"
                    >
                      Mehr anzeigen
                    </v-btn>
                    <v-btn
                      v-else-if="showCount > 5"
                      outlined
                      tile
                      color="secondary"
                      @click="showCount = 5"
                    >
                      Weniger anzeigen
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <!-- Event content -->
            <v-row class="ma-0 mt-4 px-0 mx-n3">
              <!-- Text and tags -->
              <v-col cols="12" md="7" :class="$vuetify.breakpoint.mdAndUp ? 'pr-12': ''">
                <v-row no-gutters class="services-description pb-4 pretext" v-html="$sanitize(event.info)"></v-row>
                <v-row class="pt-4 tag secondary--text" no-gutters>
                  <span class="event-box-tag text-uppercase" v-for="(tag, i) in event.tags" :key="i">
                    #{{tag}}
                  </span>
                </v-row>
              </v-col>
              <!-- Detail info -->
              <v-col cols="12" md="5">
                <v-row class="mb-3">
                  <v-col>
                    <img
                      @click="$router.push('/mitglieder/' + organisation._id)"
                      :src="s3 + organisation.pic?.name"
                      class="clickable"
                      width="250px"
                    />
                  </v-col>
                </v-row>
                <v-row @click="$router.push('/mitglieder/' + organisation._id)" no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                  >
                    ORGANISATION
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1 mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{organisation.name}}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    BEGINN
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{$moment(event.time.start).format('HH:mm')}} Uhr
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    ENDE
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{event.time.end ? $moment(event.time.end).format('HH:mm') : '-'}} Uhr
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    ADRESSE
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{computedLocation}}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2 mb-3">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    KOSTEN
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                    class="pt-3"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-4 pb-3'"
                    align-self="center"
                  >
                    <v-row>
                      <v-col
                        class="team-item-secondary py-0"
                        cols="12"
                      >
                        {{computedPrice}} (REGULÄR)
                      </v-col>
                      <template
                        v-if="event.discounts"
                      >
                        <v-col
                          v-for="(discount, i) in event.discounts"
                          :key="i"
                          class="team-item-secondary py-0"
                          :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'mb-0'"
                          cols="12"
                        >
                          {{(Math.round(discount.value * 100) / 100).toFixed(2)}}€ ({{discount.text ? discount.text.toUpperCase() : ''}})
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    PLÄTZE
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    <template v-if="event.isBookable && event.seats !== undefined && event.seats !== null">
                      {{event.seats - (event.bookings || 0) >= 0 ? event.seats - (event.bookings || 0) : 0}} von {{event.seats}} Plätzen verfügbar
                    </template>
                    <template v-else>
                      {{computedSeats}}
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    ALTER
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{computedAgeString}}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    ZIELGRUPPE
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    <template v-if="!event.genderTarget || event.genderTarget === 'a'">Alle</template>
                    <template v-else-if="event.genderTarget === 'f'">Mädels</template>
                    <template v-else-if="event.genderTarget === 'm'">Jungs</template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2">
                  <v-col
                    class="footer-item mt-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    BARRIEREFREI
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{ event.accessible ? 'Ja' : 'Nein'}}
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2" v-if="event.educational">
                  <v-col
                    class="footer-item"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    BILDUNGSANGEBOT
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    <v-icon
                      size=19
                      color="success"
                    >
                      fas fa-check
                    </v-icon>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2" v-if="event.contact">
                  <v-col
                    class="footer-item"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    KONTAKT
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    <template v-if="!(rules.email(event.contact) !== true)">
                      <a :href="'mailto:' + event.contact + '?subject=Anmeldung: ' + event.title">{{event.contact}}</a>
                    </template>
                    <template v-else-if="event.contact.toLowerCase().startsWith('http')">
                      <a target="_blank" :href="event.contact">{{event.contact}}</a>
                    </template>
                    <template v-else>
                      {{ event.contact }}
                    </template>
                  </v-col>
                </v-row>
                <v-row no-gutters class="pb-2" v-if="event.registration">
                  <v-col
                    class="footer-item"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    ANMELDUNG
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    <template v-if="!(rules.email(event.registration) !== true)">
                      <a :href="'mailto:' + event.registration + '?subject=Anmeldung: ' + event.title">{{event.registration}}</a>
                    </template>
                    <template v-else-if="event.registration.toLowerCase().startsWith('http')">
                      <a target="_blank" :href="event.registration">{{event.registration}}</a>
                    </template>
                    <template v-else>
                      {{ event.registration }}
                    </template>
                  </v-col>
                </v-row>
                <v-row
                  v-if="
                    event.isBookable &&
                    organisation.paymentInformation &&
                    event.price !== null &&
                    event.price !== undefined
                  "
                  no-gutters class="pb-2"
                >
                  <v-col
                    class="footer-item"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{ event.seats === undefined || event.seats === null || event.seats > event.bookings ? "PLATZ BUCHEN" : "WARTELISTENPLATZ BUCHEN" }}<br>
                    <span
                      class="warning--text"
                      v-if="!(event.seats === undefined || event.seats === null || event.seats > event.bookings)"
                    >
                      WARTELISTENLÄNGE: {{Math.abs(event.seats - event.bookings)}}
                    </span>
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1  mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    <v-hover
                      v-if="
                        (!event.bookingStart && !event.bookingEnd) ||
                        $moment().isAfter($moment(event.bookingStart)) && $moment().isBefore($moment(event.bookingEnd))"
                      v-slot:default="{ hover }"
                    >
                      <v-btn
                        tile
                        depressed
                        :color="hover ? 'secondary' : 'info'"
                        class="white--text"
                        @click="$router.push('/veranstaltungen/buchung/' + event._id)"
                      >
                        BUCHEN
                      </v-btn>
                    </v-hover>
                    <span v-else-if="$moment().isAfter($moment(event.bookingStart))">
                       Buchungszeitraum abgeschlossen
                    </span>
                    <span v-else>
                       Ab {{$moment(event.bookingStart).format('DD.MM.YYYY, HH:mm')}} hier buchbar
                    </span>
                  </v-col>
                </v-row>
                <v-row v-if="event.categories.length > 0" class="mb-5">
                  <v-col
                    class="footer-item mt-1 py-0 pb-1"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    KATEGORIEN
                  </v-col>
                  <v-col class="py-0" align-self="center">
                    <v-row>
                      <v-col
                        v-for="(category, i) in event.categories"
                        class="team-item-secondary"
                        :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? 'pa-0' : 'py-0'"
                        cols="12"
                        :key="i"
                      >
                        {{eventCategories.find(obj => obj.value === category).text}}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(customEntry, i) in event.custom"
                  :key="i"
                  no-gutters
                  class="pb-2"
                >
                  <v-col
                    class="footer-item mt-1 text-uppercase"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{customEntry.description}}
                  </v-col>
                  <v-col
                    class="team-item-secondary"
                    :class="$vuetify.breakpoint.smAndUp && !$vuetify.breakpoint.md ? '' : 'pt-1 mb-3'"
                    cols="12"
                    sm="6"
                    md="12"
                    lg="6"
                  >
                    {{customEntry.text}}
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          class="mt-6"
          v-if="user && user.organisation === organisation._id && event.isBookable"
        >
          <v-card-text>
            <bookings
              :event="event"
            ></bookings>
          </v-card-text>
        </v-card>
      </v-container>
      <v-dialog
        tile
        overlay-color="primary"
        overlay-opacity=".91"
        v-model="showEditor"
        fullscreen
      >
        <EventEditor
          :event="event"
          :showEditor="showEditor"
          @closeEditor="$router.push('/veranstaltungen/' + event._id)"
        />
      </v-dialog>
    </template>
  </div>
</template>

<script>

import TitleToolbar from '@/components/TitleToolbar'
import EventEditor from '@/components/EventEditor'
import { mapGetters, mapActions } from 'vuex'
import Bookings from '../components/Bookings.vue'

export default {
  name: 'EventDetails',
  components: {
    TitleToolbar,
    EventEditor,
    Bookings
  },
  data () {
    return {
      event: null,
      organisation: null,
      title: 'VERANSTALTUNGEN',
      showEditor: false,
      showCount: 5
    }
  },
  mounted () {
    this.adaptParams()
  },
  methods: {
    ...mapActions('events', {
      requestEvent: 'get'
    }),
    async adaptParams () {
      this.event = this.getEvent(this.$route.params.id)
      if (!this.event) {
        this.event = await this.requestEvent(this.$route.params.id)
      }
      this.organisation = await this.getOrganisation(this.event.organisation)
      if (this.$route.params.editor === 'editor') {
        this.showEditor = true
      } else {
        this.showEditor = false
      }
    },
    sameDay (date) {
      return this.$moment(date.start).isSame(this.$moment(date.end), 'day')
    },
    displayedDates (dates) {
      const tmpDates = dates.filter(obj => this.$moment(obj.end).isAfter(this.$moment(), 'day')).splice(0, this.showCount)
      return (tmpDates)
    }
  },
  computed: {
    ...mapGetters([
      'ac',
      'eventCategories',
      'rules',
      's3'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('events', {
      getEvent: 'get'
    }),
    ...mapGetters('organisations', {
      getOrganisation: 'get'
    }),
    computedLocation () {
      return this.event.location ? this.event.location : 'Keine Angabe'
    },
    computedPrice () {
      return this.event.price !== null && this.event.price !== undefined ? (Math.round(this.event.price * 100) / 100).toFixed(2) + '€' : 'Keine Angabe'
    },
    computedPriceDiscount () {
      return this.event.priceDiscount ? (Math.round(this.event.priceDiscount * 100) / 100).toFixed(2) + '€' : 'Keine Angabe'
    },
    computedSeats () {
      if (!this.event.seats) {
        return 'Keine Angabe'
      } else if (this.event.seats === 1) {
        return '1 Platz'
      } else {
        return this.event.seats + ' Plätze'
      }
    },
    computedAgeString () {
      if (this.event.age.from && (this.event.age.until && this.event.age.until < 99)) {
        return this.event.age.from + ' - ' + this.event.age.until + ' Jahre'
      } else if (this.event.age.from && (!this.event.age.until || this.event.age.until >= 99)) {
        return 'Ab ' + this.event.age.from + ' Jahre'
      } else if (!this.event.age.from && this.event.age.until) {
        return 'Bis ' + this.event.age.until + ' Jahre'
      } else {
        return 'Ohne Altersbeschränkung'
      }
    },
    computedShowCarouselDelimiters () {
      return this.event.pics.length > 1
    }
  },
  watch: {
    '$route.params.editor' () {
      this.adaptParams()
    }
  }
}
</script>
