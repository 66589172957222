<template>
  <div>
    <v-card
      color="lightAccent"
      class="mb-5"
    >
      <v-card-text>
        <v-toolbar
          flat
          color="transparent"
          dense
          class="mt-0 mb-6"
        >
          <v-spacer></v-spacer>
          <v-btn
            @click="$emit('closeTeamMemberEditor')"
            icon
          >
            <v-icon>
              fas fa-times
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="teamMemberEditorForm" v-model="teamMemberFormValid">
          <v-row
            v-if="isSjrTeam"
          >
            <v-col class="py-0" cols="12">
              <v-select
                v-model="tmpTeamMember.boardType"
                :items="[
                  {text: 'Team', value: 'team' },
                  {text: 'Vorstand', value: 'board' },
                  {text: 'Hauptausschuss', value: 'committee' },
                ]"
                color="secondary"
                item-color="secondary"
                label="Typ"
                dense
                outlined
                :rules="[rules.required]"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpTeamMember.name"
                outlined
                dense
                label="Name"
                :rules="[rules.longText, rules.required]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="tmpTeamMember.boardType !== 'committee'">
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpTeamMember.email"
                outlined
                dense
                label="Email"
                :rules="[rules.email]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="tmpTeamMember.boardType !== 'board' && tmpTeamMember.boardType !== 'committee'">
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpTeamMember.phone"
                outlined
                dense
                label="Telefon"
                :rules="[rules.shortText]"
                color="secondary"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="tmpTeamMember.boardType !== 'committee'">
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpTeamMember.position"
                outlined
                dense
                label="Aufgabenbereich"
                :rules="[rules.longText]"
                color="secondary"
                persistent-placeholder
                :placeholder="tmpTeamMember.boardType === 'board' ? 'z.B. Vorsitzender' : 'z.B. Azubi'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12" v-if="tmpTeamMember.boardType === 'board'">
              <v-text-field
                v-model="tmpTeamMember.primaryInfo"
                outlined
                dense
                label="Vorstand seit"
                :rules="[rules.longText]"
                color="secondary"
                persistent-placeholder
                placeholder="z.B. Seit 2023 im Vorstand des Stadtjugendring Ulm e.V."
              ></v-text-field>
            </v-col>
            <v-col class="py-0" cols="12" v-if="tmpTeamMember.boardType === 'board' || tmpTeamMember.boardType === 'committee'">
              <v-text-field
                v-model="tmpTeamMember.secondaryInfo"
                outlined
                dense
                label="Mitgliedschaften"
                :rules="[rules.longText]"
                color="secondary"
                persistent-placeholder
                :placeholder="tmpTeamMember.boardType === 'board' ? 'z.B. Mitglied im DPSG Söflingen' : 'z.B. UstA e.V.'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12">
              <v-text-field
                v-model="tmpTeamMember.order"
                outlined
                dense
                label="Anzeigeposition"
                color="secondary"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <template v-if="tmpTeamMember.boardType !== 'committee'">
            <v-row>
              <v-col
                class="py-0" cols="12"
              >
                Foto
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12">
                <PicUpload
                  ref="teamMemberPicUpload"
                  v-model="tmpTeamMember.pic"
                  @removedUploadedFile="removedUploadedFile()"
                  @fileAdd="$nextTick(() => { $refs.teamMemberEditorForm.validate() })"
                  :acceptedMimeTypes="['image/png', 'image/jpg', 'image/jpeg']"
                  :maxFileSize="0.5"
                  :maxFiles="1"
                  bgColor="lightAccent"
                  :scaleToFit="[400, 400]"
                  :resizeQuality="75"
                ></PicUpload>
              </v-col>
            </v-row>
          </template>
        </v-form>
        </v-card-text>
        <v-card-actions
          class="px-4 pb-4"
        >
          <v-hover
            v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="$emit('removeTeamMember')"
            >
              ENTFERNEN
            </v-btn>
          </v-hover>
          <v-spacer></v-spacer>
          <v-hover v-slot:default="{ hover }">
            <v-btn
              tile
              depressed
              :disabled="!teamMemberFormValid"
              :color="hover ? 'secondary' : 'info'"
              class="white--text"
              @click="$emit('addTeamMember', tmpTeamMember)"
            >
              {{ tmpTeamMember._id ? 'ÜBERNEHMEN': 'HINZUFÜGEN' }}
            </v-btn>
          </v-hover>
        </v-card-actions>
      </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PicUpload from './PicUpload.vue'

export default {
  name: 'TeamMemberEditor',
  props: [
    'isSjrTeam',
    'value'
  ],
  components: {
    PicUpload
  },
  data () {
    return {
      teamMemberFormValid: false,
      tmpTeamMember: {}
    }
  },
  mounted () {
    this.adapt()
  },
  methods: {
    ...mapActions([
      'showSnackbar'
    ]),
    removedUploadedFile () {
      this.$emit('removeUploadedTeamMemberPic')
    },
    adapt () {
      if (this.value) {
        this.tmpTeamMember = JSON.parse(JSON.stringify(this.value))
      }
    },
    async upload () {
      await this.$refs.teamMemberPicUpload.upload()
    }
  },
  computed: {
    ...mapGetters([
      'rules'
    ])
  },
  watch: {
    value () {
      this.adapt()
    },
    tmpTeamMember: {
      deep: true,
      handler () {
        if (
          this.tmpTeamMember?.pic?.name !== this.value?.pic?.name ||
          this.tmpTeamMember?.name !== this.value?.name ||
          this.tmpTeamMember?.phone !== this.value?.phone ||
          this.tmpTeamMember?.email !== this.value?.email ||
          this.tmpTeamMember?.position !== this.value?.position ||
          this.tmpTeamMember?.order !== this.value?.order ||
          this.tmpTeamMember?.boardType !== this.value?.boardType ||
          this.tmpTeamMember?.primaryInfo !== this.value?.primaryInfo ||
          this.tmpTeamMember?.secondaryInfo !== this.value?.secondaryInfo
        ) {
          this.tmpTeamMember.isDirty = true
        } else {
          delete this.tmpTeamMember.isDirty
        }
      }
    }
  }
}
</script>
