<template>
  <div>
    <vue-headful
      title="SJR Ulm - Mitglieder"
      description="Hier findet ihr alle Mitglieder des Stadtjugendrings Ulm e.V."
    />
    <!-- Toolbar -->
    <v-toolbar
      flat
      color="#f5f7fa"
    >
      <v-toolbar-title
        class="section-title primary--text"
      >
        MITGLIEDER
      </v-toolbar-title>
    </v-toolbar>
    <!-- Filters -->
    <v-container fluid>
    <v-row dense>
      <v-col>
        <v-btn
          tile
          icon
          outlined
          color="secondary"
          class="mr-4"
          @click="mode = 'grid'"
          :class="mode !== 'grid' ? 'action-icon-button': 'action-icon-button-active'"
        >
          <span class="fas fa-grip-horizontal"></span>
        </v-btn>
        <v-btn
          tile
          icon
          outlined
          color="secondary"
          class="mr-4"
          @click="mode = 'map'"
          :class="mode !== 'map' ? 'action-icon-button': 'action-icon-button-active'"
        >
          <span class="fas fa-map-marker-alt"></span>
        </v-btn>
        <v-btn
          tile
          icon
          outlined
          color="secondary"
          @click="mode = 'list'"
          :class="mode !== 'list' ? 'action-icon-button': 'action-icon-button-active'"
        >
          <span class="fas fa-th-list"></span>
        </v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn
          text
          :color="computedFiltersActive > 0 ? 'primary' : 'secondary'"
          @click="showFilters = true"
        >
          FILTER {{ computedFiltersActive > 0 ? '(' + computedFiltersActive + ')' : ''}}
        </v-btn>
        <v-btn
          v-if="computedFiltersActive > 0"
          icon
          color="secondary"
          @click="resetFilters()"
        >
          <v-icon small>fas fa-trash</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-text-field
          label="Suche"
          :color="search === '' ? 'secondary' : 'primary'"
          class="text-field-search"
          hide-details
          v-model="search"
          clearable
        >
          <template slot="append">
            <v-icon
              v-if="search === ''"
              class="m-3"
              small
              color="secondary"
            >
              fa-search
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    </v-container>
    <!-- Editor -->
    <div
      ref="organisationsEditorContainer"
    >
      <OrganisationEditor
        ref="organisationEditor"
        :organisation="getOrganisation($route.params.id)"
        :showEditor="showEditor"
        v-show="
          ac &&
          user &&
          (
            $route.params.id !== 'neu' &&
            (
              ac.can(user.role).updateAny('organisation').granted ||
              (
                ac.can(user.role).updateOwn('organisation').granted &&
                $route.params.id === user.organisation
              )
            ) ||
            (
              $route.params.id === 'neu' &&
              (
                ac.can(user.role).createAny('organisation').granted
              )
            )
          )
        "
      />
    </div>
    <v-container v-show="mode === 'list'" fluid>
      <v-list>
        <v-list-item
          v-for="organisation in filteredOrganisations"
          :key="organisation._id"
          :ref="organisation._id"
        >
          <v-list-item-content
            @click="$router.push('/mitglieder/' + organisation._id)"
          >
            <v-list-item-title
              class="clickable members-list-item-title"
            >
              {{organisation.name}}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              color="secondary"
              icon
              @click="$router.push('/mitglieder/editor/' + organisation._id)"
              v-if="
                !$route.params.id &&
                ac &&
                user &&
                (
                  ac.can(user.role).updateAny('organisation').granted ||
                  (
                    ac.can(user.role).updateOwn('organisation').granted &&
                    user.organisation === organisation._id
                  )
                )
              "
            >
              <v-icon
                small
              >fas fa-edit</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-container>
    <v-container v-show="mode === 'grid'" fluid>
      <v-row dense>
        <OrganisationContainer
          v-for="organisation in filteredOrganisations"
          :key="organisation._id"
          :organisation="organisation"
        />
      </v-row>
    </v-container>
    <template v-if="mapReady">
      <div v-show="mode === 'map'">
        <div
          :key="i"
          v-for="(marker, i) in filteredOrganisations"
        >
        </div>
        <v-container fluid>
            <GmapMap
              :center="center"
              :zoom="12"
              style="width: 100%; height: 500px;"
              ref="gmap"
              :options="mapOptions"
            >
              <GmapMarker
                :ref="'marker' + i"
                :key="i"
                v-for="(organisation, i) in filteredOrganisations"
                :position="{
                  lat: organisation.location.lat,
                  lng: organisation.location.lng
                }"
                :clickable="true"
                @mouseover="enterMarker(i, organisation.name)"
                @mouseout="leaveMarker(i)"
                @click="clickMarker(organisation._id)"
                :icon="{
                  url: s3 + organisation.pic?.name,
                  scaledSize: { width: 44, height: 44 / (organisation.aspect || 1) },
                  labelOrigin: { x: 15, y: 40 }
                }"
              />
            </GmapMap>
        </v-container>
      </div>
    </template>
    <v-dialog
      tile
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showEditorDialog"
      fullscreen
      v-if="
        ac &&
        user &&
        (
          $route.params.id !== 'neu' &&
          (
            ac.can(user.role).updateAny('organisation').granted ||
            (
              ac.can(user.role).updateOwn('organisation').granted &&
              $route.params.id === user.organisation
            )
          ) ||
          (
            $route.params.id === 'neu' &&
            (
              ac.can(user.role).createAny('organisation').granted
            )
          )
        )
      "
    >
      <OrganisationEditor
        :organisation="getOrganisation($route.params.id)"
        :showEditor="showEditorDialog"
      />
    </v-dialog>
    <v-dialog
      tile
      max-width="600"
      overlay-color="primary"
      overlay-opacity=".91"
      v-model="showFilters"
    >
      <v-card
        tile
        flat
      >
        <v-card-text>
          <v-row>
            <v-col class="text-right">
              <v-btn
                icon
                @click="showFilters = false"
              >
                <v-icon>
                  fas fa-times
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-divider class="mb-3"></v-divider>
          <v-row>
            <v-col>
              <v-autocomplete
                label="Kategorie"
                multiple
                v-model="filterCategories"
                clearable
                color="secondary"
                item-color="secondary"
                :items="organisationCategories"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-hover v-slot:default="{ hover }">
              <v-btn
                tile
                depressed
                :color="hover ? 'secondary' : 'info'"
                class="white--text"
                @click="showFilters = false"
              >
                Schließen
              </v-btn>
            </v-hover>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import OrganisationEditor from '@/components/OrganisationEditor'
import OrganisationContainer from '@/components/OrganisationContainer'
import { mapGetters } from 'vuex'

export default {
  name: 'Organisations',
  components: {
    OrganisationEditor,
    OrganisationContainer
  },
  data () {
    return {
      mode: 'grid',
      mapReady: false,
      selected: undefined,
      showEditor: false,
      showFilters: false,
      selectedView: 0,
      center: { lat: 48.3970286, lng: 9.99 },
      infoOptions: {
        pixelOffset: {
          width: -5,
          height: -55
        },
        content: ''
      },
      mapOptions: {
        styles: [
          {
            elementType: 'geometry',
            stylers: [
              {
                color: '#f5f5f5'
              }
            ]
          },
          {
            elementType: 'labels.icon',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161'
              }
            ]
          },
          {
            elementType: 'labels.text.stroke',
            stylers: [
              {
                color: '#f5f5f5'
              }
            ]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'administrative.land_parcel',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#bdbdbd'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'poi.business',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e'
              }
            ]
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
              {
                color: '#ffffff'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#757575'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [
              {
                color: '#dadada'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#616161'
              }
            ]
          },
          {
            featureType: 'road.local',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road.local',
            elementType: 'labels',
            stylers: [
              {
                visibility: 'off'
              }
            ]
          },
          {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e'
              }
            ]
          },
          {
            featureType: 'transit.line',
            elementType: 'geometry',
            stylers: [
              {
                color: '#e5e5e5'
              }
            ]
          },
          {
            featureType: 'transit.station',
            elementType: 'geometry',
            stylers: [
              {
                color: '#eeeeee'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [
              {
                color: '#c9c9c9'
              }
            ]
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
              {
                color: '#9e9e9e'
              }
            ]
          }
        ]
      },
      infoWindowPos: null,
      infoWinOpen: false,
      filterCategories: [],
      search: '',
      showEditorDialog: false
    }
  },
  mounted () {
    if (localStorage.getItem('organisationsMode')) {
      this.mode = localStorage.getItem('organisationsMode')
    } else {
      localStorage.setItem('organisationsMode', this.mode)
    }
    this.initImages()
    this.adaptParams()
  },
  methods: {
    async initImages () {
      let c = 0
      for (const organisation of this.filteredOrganisations) {
        const timeout = setTimeout(() => {
          // console.error('No pic for "' + organisation.name + '" marker')
          c++
          if (c === this.filteredOrganisations.length) {
            this.initGmap()
            this.mapReady = true
          }
        }, 2000)
        const img = new Image()
        img.addEventListener('load', (e) => {
          clearTimeout(timeout)
          organisation.aspect = e.target.naturalWidth / e.target.naturalHeight
          c++
          if (c === this.filteredOrganisations.length) {
            this.initGmap()
            this.mapReady = true
          }
        })
        img.src = process.env.VUE_APP_SERVER_URL + '/pics/' + organisation._id + '/logo/' + organisation.pic
      }
    },
    async initGmap () {
      if (this.$refs.gmap) {
        await this.$gmapApiPromiseLazy()
        // eslint-disable-next-line no-undef
        const bounds = new google.maps.LatLngBounds()
        for (const organisation of this.filteredOrganisations) {
          // eslint-disable-next-line no-undef
          const myLatLng = new google.maps.LatLng({
            lat: organisation.location.lat,
            lng: organisation.location.lat
          })
          bounds.extend(myLatLng)
        }
        this.$refs.gmap.fitBounds(bounds)
      }
    },
    enterMarker (index, text) {
      this.$refs['marker' + index][0].$markerObject.setLabel({ text: text.toUpperCase(), color: '#003151', fontWeight: 'bold' })
      this.$refs['marker' + index][0].$markerObject.setZIndex(1000)
    },
    leaveMarker (index, text) {
      this.$refs['marker' + index][0].$markerObject.setLabel({ text: ' ' })
      this.$refs['marker' + index][0].$markerObject.setZIndex(0)
    },
    clickMarker (id) {
      this.$router.push('/mitglieder/' + id)
    },
    openEditor (organisation) {
      if (this.mode === 'list') {
        if (organisation) {
          this.$nextTick()
            .then(() => {
              this.$refs[organisation._id][0].$el.parentNode.insertBefore(this.$refs.organisationEditor.$el, this.$refs[organisation._id][0].$el.nextSibling)
              setTimeout(() => {
                this.$vuetify.goTo(this.$refs.organisationEditor.$el, { offset: 60 })
                this.showEditor = true
              }, 100)
            })
        } else {
          this.showEditor = true
        }
      } else {
        this.showEditorDialog = true
      }
    },
    closeEditor () {
      this.$refs.organisationsEditorContainer.parentNode.insertBefore(this.$refs.organisationEditor.$el, this.$refs.organisationsEditorContainer.nextSibling)
      this.showEditor = false
      this.showEditorDialog = false
    },
    adaptParams () {
      if (this.$route.params.id) {
        this.openEditor(this.getOrganisation(this.$route.params.id))
      } else {
        this.closeEditor()
      }
    },
    resetFilters () {
      this.filterCategories = []
    }
  },
  computed: {
    ...mapGetters([
      'ac',
      'organisationCategories',
      's3'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    }),
    ...mapGetters('organisations', {
      organisations: 'list',
      getOrganisation: 'get'
    }),
    filteredOrganisations () {
      return this.$sortByProperty(
        this.organisations.filter(obj =>
          obj.isActive &&
          obj.pic &&
          obj.type === 'member' &&
          (this.filterCategories.length === 0 || this.filterCategories.some(r => obj.categories.indexOf(r) >= 0)) &&
          (this.search === '' || !this.search || obj.name.toLowerCase().includes(this.search.toLowerCase()))
        ), 'name'
      )
    },
    computedFiltersActive () {
      let count = 0
      if (this.filterCategories.length !== 0) { ++count }
      return count
    }
  },
  watch: {
    '$route.params.id' () {
      this.adaptParams()
    },
    mode () {
      localStorage.setItem('organisationsMode', this.mode)
    }
  }
}
</script>
