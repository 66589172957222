<template>
  <v-col
    cols="6"
    sm="4"
    md="3"
    class="pa-6"
  >
    <v-card
      flat
      color="transparent"
    >
      <v-row>
        <v-hover v-slot:default="{ hover }">
          <v-card
            outlined
            tile
            class="pa-3 no-overflow"
            :class="hover ? 'elevation-3' : ''"
          >
            <v-img
              v-if="organisation.pic"
              :src="s3 + organisation.pic?.name"
              contain
              @click="showOrganisation(organisation)"
              class="event-box-image clickable"
              :class="hover || organisation.type !== 'institution' ? '' : 'event-box-image-grayscaled'"
            ></v-img>
            <v-img
              v-if="organisation.type === 'institution'"
              src="/img/label.svg"
              contain
              width="20%"
              aspectRatio=1
              class="event-box-logo"
              :class="hover ? 'event-box-logo-hover' : ''"
            ></v-img>
          </v-card>
        </v-hover>
      </v-row>
      <v-row>
        <v-col
          class="px-0 section-sub-title primary--text text-uppercase"
        >
          <span
            class="clickable"
            @click="showOrganisation(organisation)"
          >
            {{organisation.name}}
          </span>
          <v-btn
            color="secondary"
            class="mb-1"
            icon
            @click="$router.push($route.path + '/editor/' + organisation._id)"
            v-if="
              !$route.params.id &&
              ac &&
              user &&
              (
                ac.can(user.role).updateAny('organisation').granted ||
                (
                  ac.can(user.role).updateOwn('organisation').granted &&
                  user.organisation === organisation._id
                )
              )
            "
          >
            <v-icon
              small
            >fas fa-edit</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrganisationContainer',
  props: [
    'organisation'
  ],
  methods: {
    showOrganisation (organisation) {
      if (organisation.type === 'member' || organisation.type === 'institution') {
        this.$router.push('/mitglieder/' + organisation._id)
      } else if (organisation.link) {
        window.open(organisation.link, '_newtab')
      }
    }
  },
  computed: {
    ...mapGetters([
      'ac',
      's3'
    ]),
    ...mapGetters('auth', {
      user: 'user'
    })
  }
}
</script>
